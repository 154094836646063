import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MdManageHistory } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { MdPostAdd } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import axios from 'axios';
import Aside from '../Aside/Aside';
import { IoMdSettings } from "react-icons/io";
import { LuLogOut } from "react-icons/lu";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import Smallicon from '../../Components/heart-icon.png';
import Lady from './lady.jpg';
import Men from './men.jpg';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';

const Textform = () => {
    const [data, setData] = useState([]);
    const [profileData, setProfileData] = useState(null);
    const { id } = useParams();
    const componentRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`http://localhost:8000/fetchmember`);
                const data = await response.json();
                setProfileData(data);
                console.log(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://localhost:8000/api/singlejathagam`);
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [id]);

    const handleBackClick = () => {
        window.history.back();
    };

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
    };

    const formatTime = (timeStr) => {
        if (!timeStr) return '';
        const [hours, minutes] = timeStr.split(':');
        const intHours = parseInt(hours, 10);
        const intMinutes = parseInt(minutes, 10);
        const period = intHours >= 12 ? 'PM' : 'AM';
        const formattedHours = intHours % 12 || 12;
        const formattedMinutes = intMinutes < 10 ? `0${intMinutes}` : intMinutes;
        return `${formattedHours}:${formattedMinutes} ${period}`;
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleDownloadPdf = () => {
        const doc = new jsPDF();
        doc.fromHTML(componentRef.current, 15, 15, {
            width: 170,
        });
        doc.save('profile.pdf');
    };

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Aside />
                    <div className="layout-page">
                                   <div className='row mb-3 mx-5 mt-4 '>
                                        <div className='col-lg-6 text-start'>
                                            <button onClick={handleBackClick} className="btn btn-secondary">Back to Member List</button>
                                        </div>
                                        <div className=' col-lg-6 text-end'>
                                            <button onClick={handlePrint} className="btn btn-primary me-2">Print</button>
                                            <button onClick={handleDownloadPdf} className="btn btn-primary">Download PDF</button>
                                        </div>
                                    </div>
                    <div className='container gen_print row mx-auto border  my-4 p-4' ref={componentRef}>
    {profileData ? ( 
      
                  <>

        <div className='border-bottom pt-2 pb-4'>
            <h2 className='fw-bold'>துளிர் மேட்ரிமோனி</h2>
            <p>159F, விஜயாபுரி,V.E.P, காம்ப்ளக்ஸ்,</p>
            <p>எட்டயபுரம் வளைவு ரோடு, படித்துறை எதிரில், கோவில்பட்டி - 628501</p>
        </div>
        <div className='col-lg-6 mt-4 row'>
        <div className='mb-3 text-start row'>
                <span className='col-lg-7 fw-bold'>பதிவு எண்</span>
                <span className='col-lg-5'>: {profileData.body.reg_id}</span>
            </div>
        <div className='mb-3 text-start row'>
                <span className='col-lg-7 fw-bold'>பெயர்</span>
                <span className='col-lg-5'>: {profileData.body.name}</span>
            </div>
            
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>பிறந்த தேதி</span>
                <span className='col-lg-5'>: {formatDate(profileData.body.dob)}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>உயரம்</span>
                <span className='col-lg-5'>: {profileData.body.height}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>இனம்</span>
                <span className='col-lg-5'>: {profileData.body.caste}</span>
            </div>
           
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>கல்வி</span>
                <span className='col-lg-5'>: {profileData.body.education}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>இடம்</span>
                <span className='col-lg-5'>: {profileData.body.address}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>தந்தை பெயர்</span>
                <span className='col-lg-5'>: {profileData.body.father_name}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சகோதரர்கள் / சகோதரிகள்</span>
                <span className='col-lg-5'>: {profileData.body.brothers_count} / {profileData.body.sisters_count} </span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சகோதரர் திருமணமானவர்கள்</span>
                <span className='col-lg-5'>: {profileData.body.brother_married}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சகோதரி திருமணமானவர்கள்</span>
                <span className='col-lg-5'>: {profileData.body.sister_married}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>ராசி</span>
                <span className='col-lg-5'>: {profileData.body.zodiacsign}</span>
            </div>
        </div>
        <div className='col-lg-6 mt-4 row'>
       
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>பிறந்த நேரம்</span>
                <span className='col-lg-5'>: {formatTime(profileData.body.birthtime)}</span>
            </div>
            <div className='mb-2 text-start row'>
                <span className='col-lg-7 fw-bold'>பாலினம்</span>
                <span className='col-lg-5'>: {profileData.body.gender}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>வயது</span>
                <span className='col-lg-5'>: {profileData.body.age}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சாதி</span>
                <span className='col-lg-5'>: {profileData.body.caste}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>துணை சாதி</span>
                <span className='col-lg-5'>: {profileData.body.subcaste}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சொந்த ஊர்</span>
                <span className='col-lg-5'>: {profileData.body.city}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>குல தெய்வம்</span>
                <span className='col-lg-5'>: {profileData.body.kuladeivam}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>வேலை</span>
                <span className='col-lg-5'>: {profileData.body.occupation}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>சம்பளம்</span>
                <span className='col-lg-5'>: {profileData.body.annual_income}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>தாய் பெயர்</span>
                <span className='col-lg-5'>: {profileData.body.mother_name}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>தொலைபேசி எண்</span>
                <span className='col-lg-5'>: {profileData.body.phonenumber}</span>
            </div>
            <div className='mb-3  text-start row'>
                <span className='col-lg-7 fw-bold'>நட்சத்திரம்</span>
                <span className='col-lg-5'>: {profileData.body.star}</span>
            </div>
          
        </div>  
        <hr className='mt-3' />
        {data ? (
        <>
        <div className='text-center mb-3'>
          <h3>ஜாதக விவரம்</h3>
        </div>
        <div className='row'>
        <div className='col-lg-12 mt-3 m-auto mb-2 row'>
        <div className='col-lg-3 d-flex mb-2 align-items-center'>
            <span className="fw-bold mb-0 me-2">திசை இருப்பு</span>
            <span>: {data.thisaiirupu}</span>
          </div>
          <div className='col-lg-3 d-flex mb-2 align-items-center'>
      <span class="fw-bold mb-0 me-2">ஆண்டு</span>
      <span>: {data.year}</span>
    </div>
    <div className='col-lg-3 d-flex mb-2 align-items-center'>
      <span class="fw-bold mb-0 me-2">மாதம்</span>
      <span>: {data.month}</span>
    </div>
    <div className='col-lg-3 d-flex mb-2 align-items-center'>
      <span class="fw-bold mb-0 me-2">நாள்</span>
      <span>: {data.days}</span>
    </div>
       
      </div>
      </div>
      
      <div className='col-lg-6 mt-5 mb-2'>
        <table class="table table-bordered">
        <tbody>
            <tr>
            <th scope="row">{data.rasi1}</th>
            <td>{data.rasi2}</td>
            <td>{data.rasi3}</td>
            <td>{data.rasi4}</td>
            </tr>
            <tr>
            <th scope="row">{data.rasi5}</th>
            <td colspan="2" rowSpan={2}>ராசி</td>
            <td>{data.rasi6}</td>
            </tr>
            <tr>
            <th scope="row">{data.rasi7}</th>
            <td >{data.rasi8}</td>
            </tr>
            <tr>
            <th scope="row">{data.rasi9}</th>
            <td >{data.rasi10}</td>
            <td>{data.rasi11}</td>
            <td>{data.rasi12}</td>
            </tr>
        </tbody>
        </table>
        </div>
        <div className='col-lg-6 mt-5 mb-2'>
        <table class="table table-bordered">
        <tbody>
            <tr>
            <th scope="row">{data.amsam1}</th>
            <td>{data.amsam2}</td>
            <td>{data.amsam3}</td>
            <td>{data.amsam4}</td>
            </tr>
            <tr>
            <th scope="row">{data.amsam5}</th>
            <td colspan="2" rowSpan={2}>அம்சம்</td>
            <td>{data.amsam6}</td>
            </tr>
            <tr>
            <th scope="row">{data.amsam7}</th>
            <td >{data.amsam8}</td>
            </tr>
            <tr>
            <th scope="row">{data.amsam9}</th>
            <td >{data.amsam10}</td>
            <td>{data.amsam11}</td>
            <td>{data.amsam12}</td>
            </tr>
        </tbody>
        </table>
       
        </div>
      <div className='mt-2 mb-2'>
        <p>This profile taken from <b>tulirmatrimony.com</b></p>
        </div>
      </>
       ) : (
        <p>Loading...</p>
      )}
        
        <div className='mt-2 mb-2'>
        <p>மற்ற அமைப்பாளர்களுடன் தொடர்பு கிடையது</p>
        </div>
                  </>
                  
                )
                 : (
                    <p>Loading...</p>
                )}
                </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Textform;
